
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { includes } from "lodash";
import TermConditionModal from "@/components/modals/term-condition/TermCondition.vue";

export default defineComponent({
  name: "Detail Sekolah",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
    TermConditionModal,
  },

  data() {
    return {
      modalId: "term_condition_modal",
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      listSekolah: [],

      url: "",
      errorsMessage: {
        errorjumlah: "",
        errorproductSekolah: "",
      },
      hideDescription: false,
      target: {
        idSekolah: localStorage.getItem("user_account")
          ? JSON.parse(localStorage.getItem("user_account") as string).schoolId
          : "",
        jumlah: "",
        catatan: "",
      },

      detail: {
        name: "High-Back Gaming Chair",
        createTime: "2022-02-15T13:33:33",
        creatorId: "",
        deleteBy: "",
        deleteTime: "",
        description:
          "Pu Leather COMFORT FROM EVERY ANGLE - Our high-back gaming chair is thickly cushioned for maximum comfort, whether you’re spending long hours at the office, in front of the computer, or gaming. Covered with breathable premium PU leather, the chair is equipped with freely adjustable lumbar support and a headrest pillow to protect your spine and neck, while the adjustable reclining feature allows users to lock in any reclining position, from 90-135°. MAXIMUM MOBILITY - Our chair are designed with long-lasting ergonomic construction, equipped with a BIFMA-certified heavy-duty metal base, providing stability and mobility with a 360° range of motion on smooth-rolling nylon casters so you can glide easily between work stations. EASY ASSEMBLY – Our chair comes ready to assemble, with all the hardware and necessary tools. With step-by-step instructions, you’ll be set up and ready to game, take on the office in about 10-15 minutes! CUSTOMER GUARANTEE – We want all of our customers to feel 100% satisfied, and ready to unwind from the comfort of our High-Back Office Chair. If you’re not satisfied, or have any questions, please contact BestOffice customer service. BUILT TO LAST - The high-back chair is built to endure, with a heavy-duty metal base, offering great stability and mobility for a capacity of up to 250 lbs.",
        discount: 0,
        editorId: "",
        imageshow: "",
        imgUrl: ["null", "null", "null", "null", "null"],
        isDelete: false,
        price: 2400000,
        productCatagory: "",
        stock: 0,
        updateTime: "",
        _id: "",
      },
    };
  },

  beforeMount() {
    this.getIdSekolah();
    this.getListSekolah();
    this.heightCalculation();
  },
  watch: {},

  methods: {
    heightCalculation() {
      let element = document.getElementsByClassName("item-description");
      console.log(element, element.item(0));
      for (let index = 0; index < element.length; index++) {}
    },
    showHide() {
      this.hideDescription = !this.hideDescription;
    },
    changeImage(item) {
      console.log(item);
      this.detail.imageshow = item;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    createCart() {
      const toast = useToast();
      let data = {
        quantity: this.target.jumlah,
        note: this.target.catatan,
        productData: this.detail,
      };
      ApiService.postWithData(
        `crmv2/order/cart/admin/${this.target.idSekolah}`,
        data
      ).then((res) => {
        toast.success("Berhasil Menambahkan Keranjang");
        this.$router.push("/superadmin/daftar-produk");
      });
    },
    getIdSekolah() {
      store.dispatch(Actions.VERIFY_AUTH);

      this.isLoading = true;

      const toast = useToast();
      const $route = useRoute();

      ApiService.getWithoutSlug(
        "crmv2/order/product/admin/" + this.$route.params._id
      )
        .then((res) => {
          // this.defaultItem = response.data.data.result;
          this.detail = res.data;
          this.detail.imgUrl = Object.values(res.data.imageProduct);
          Object.values(res.data.imageProduct).map((item, index) => {
            setTimeout(() => {
              ApiService.getWithoutSlug(
                "crmv2/main_image/image/get/base64/" + item
              )
                .then((resImg) => {
                  this.detail.imgUrl[
                    index
                  ] = `data:image/jpg;base64,${resImg.data}`;

                  if (item) {
                    this.detail.imageshow = `data:image/jpg;base64,${resImg.data}`;
                  }
                  // this.detail.imageshow = `data:image/jpg;base64,${resImg.data}`;
                })
                .catch((err) => {
                  toast.error(err?.res?.data?.detail);
                  // this.isLoading = false;
                });
            }, 500);
          });

          this.isLoading = false;
        })
        .catch((e) => {
          toast.error(e.res.data.detail);

          this.isLoading = false;
        });
    },

    getListSekolah() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/main_card/util/combo_company").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSekolah", JSON.stringify(data));
        }
      );
      this.isLoading = false;
    },
    getImage(imageName) {
      const toast = useToast();
      let returnImage = "";
      ApiService.getWithoutSlug(
        "crmv2/main_image/image/get/base64/" + imageName
      )
        .then((resImg) => {
          // console.log(resImg.data);
          return `data:image/jpg;base64,${resImg.data}`;
          returnImage = `data:image/jpg;base64,${resImg.data}`;
        })
        .catch((err) => {
          toast.error(err?.res?.data?.detail);
        });
      // return returnImage;
    },
  },
});
