
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "term-condition-modal",
  components: {},
  props: {
    schoolId: String,
    quantity: String,
    note: String,
    data: Object,
  },

  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    createCart() {
      const toast = useToast();
      let data = {
        quantity: this.$props.quantity,
        note: this.$props.note,
        productData: this.$props.data,
      };
      ApiService.postWithData(
        `crmv2/order/cart/admin/${this.$props.schoolId}`,
        data
      )
        .then((res) => {
          toast.success("Berhasil Menambahkan Keranjang");
          this.$router.push("/superadmin/daftar-produk");
        })
        .catch((e) => {
          toast.error("");
        });
    },
  },
});
