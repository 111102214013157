
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import DetailProduct from "@/components/1crm/product/daftar-product/detail-product.vue";

export default defineComponent({
  name: "Cabang",
  components: {
    ErrorMessage,
    Field,
    Form,
    DetailProduct,
  },

  setup() {
    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH);
      setCurrentPageBreadcrumbs("Detail Produk", ["Data"]);
    });
  },
});
